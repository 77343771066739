import React from 'react'
import { useGetTickets } from '@src/api/tickets'
import { Group, ItemSkeleton, Text, VStack } from '@revolut/ui-kit'
import { BugReportTicket } from '@components/HelpCenter/components/BugReportTicket'
import { TicketStatus, ticketStatuses } from '@src/interfaces/tickets'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { ROUTES } from '@src/constants/routes'
import { PageWrapper } from '@src/components/Page/Page'
import { PageBody } from '@src/components/Page/PageBody'

export const Tickets = () => {
  const archivedStatus: TicketStatus = 'closed'
  const activeStatuses = ticketStatuses
    .filter(status => status !== archivedStatus)
    .map(status => ({ id: status, name: status }))

  const { data: activeTickets, isLoading: isLoadingActiveTickets } = useGetTickets([
    {
      filters: activeStatuses,
      columnName: 'current_status_name',
    },
  ])

  const archivedStatuses = ticketStatuses
    .filter(status => status === archivedStatus)
    .map(status => ({ id: status, name: status }))

  const { data: archivedTickets, isLoading: isLoadingArchivedTickets } = useGetTickets([
    {
      filters: archivedStatuses,
      columnName: 'current_status_name',
    },
  ])

  if (isLoadingActiveTickets || isLoadingArchivedTickets) {
    return (
      <Group>
        <ItemSkeleton />
        <ItemSkeleton />
        <ItemSkeleton />
      </Group>
    )
  }

  if (!activeTickets || !archivedTickets) {
    return null
  }

  return (
    <PageWrapper>
      <PageHeader title="All issues" backUrl={ROUTES.MAIN} />
      <PageBody>
        {activeTickets.count > 0 ? (
          <VStack space="s-8">
            <Text variant="primary" use="div" pb="s-8">
              Active issues
            </Text>
            <Group>
              {activeTickets.results.map(ticket => (
                <BugReportTicket ticket={ticket} key={ticket.id} />
              ))}
            </Group>
          </VStack>
        ) : null}
        {archivedTickets.count > 0 ? (
          <VStack space="s-8">
            <Text variant="primary" use="div" pb="s-8">
              Archived
            </Text>
            <Group>
              {archivedTickets.results.map(ticket => (
                <BugReportTicket
                  ticket={ticket}
                  key={ticket.id}
                  iconColor="grey-tone-50"
                />
              ))}
            </Group>
          </VStack>
        ) : null}
      </PageBody>
    </PageWrapper>
  )
}
