import React from 'react'
import { JobPostingInterface, PublishingStatuses } from '@src/interfaces/jobPosting'
import {
  MoreBar,
  StatusPopup,
  Tooltip,
  useStatusPopup,
  useTooltip,
} from '@revolut/ui-kit'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { connect } from 'lape'
import { EntityPermissions } from '@src/store/auth/types'

const PublishButton = () => {
  const { values, loading, submit } = useLapeContext<JobPostingInterface>()
  const statusPopup = useStatusPopup()
  const tooltip = useTooltip()
  const canChange = !!values.field_options?.actions?.includes?.(EntityPermissions.Change)

  const onClickPublish = async () => {
    const fallbackStatus = values.status

    values.status =
      values.status === PublishingStatuses.unpublished
        ? PublishingStatuses.fully_published
        : PublishingStatuses.unpublished

    try {
      await submit()

      statusPopup.show(
        <StatusPopup variant="success">
          <StatusPopup.Title>
            Job posting{' '}
            {values.status === PublishingStatuses.unpublished
              ? 'unpublished'
              : 'published'}
          </StatusPopup.Title>
        </StatusPopup>,
      )
    } catch (error) {
      values.status = fallbackStatus
      const errorsData = error?.response?.data
      const statusError = errorsData?.status?.[0]
      const compensationBandsError = errorsData?.salary_bands_by_location
      statusPopup.show(
        <StatusPopup variant="error">
          <StatusPopup.Title>
            {statusError || compensationBandsError || 'Error updating job posting status'}
          </StatusPopup.Title>
        </StatusPopup>,
      )
    }
  }

  if (!canChange) {
    return null
  }

  return (
    <>
      <MoreBar.Action
        aria-disabled={values.approval_status !== 'approved'}
        onClick={() => {
          if (values.approval_status !== 'approved') {
            onClickPublish()
          }
        }}
        pending={loading}
        {...tooltip.getAnchorProps()}
      >
        {values.status === PublishingStatuses.unpublished ? 'Publish' : 'Unpublish'}
      </MoreBar.Action>
      {values.approval_status !== 'approved' && (
        <Tooltip {...tooltip.getTargetProps()}>
          Posting must be approved in order to be published
        </Tooltip>
      )}
    </>
  )
}

export default connect(PublishButton)
