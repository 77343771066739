import {
  VStack,
  Input,
  Text,
  Icon,
  Token,
  chain,
  List,
  Button,
  InputProps,
  InputGroup,
  HStack,
  Widget,
  Caption,
  Link,
  Spacer,
  CheckboxSelect,
  SelectOptionType,
} from '@revolut/ui-kit'
import { addRoadmaps, getAllUnassignedRoadmaps } from '@src/api/roadmaps'
import RadioSelectInput from '@src/components/Inputs/RadioSelectInput/RadioSelectInput'
import { EntityTypes, selectorKeys } from '@src/constants/api'
import { IssueTypes } from '@src/interfaces/deliverables'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { UnassignedRoadmapInterface } from '@src/interfaces/roadmaps'
import { TeamInterface } from '@src/interfaces/teams'
import { issueIconsByType } from '@src/pages/Forms/AddRoadmap/AddRoadmap'
import debounce from 'lodash/debounce'
import pluralize from 'pluralize'
import React, { useEffect, useMemo, useRef, useState } from 'react'

const initialForceSelected = {}

export const JiraSearchWidget = ({
  onSelectionChange,
  forceSelected = initialForceSelected,
  ...inputProps
}: {
  onSelectionChange: (epics: Record<string, UnassignedRoadmapInterface>) => void
  forceSelected?: Record<string, UnassignedRoadmapInterface>
} & InputProps<'input'>) => {
  const inputRef = useRef(null)
  const [searchValue, setSearchValue] = useState<string>()
  const [searchPending, setSearchPending] = useState(false)
  const [options, setOptions] = useState<UnassignedRoadmapInterface[]>([])
  const [selected, setSelected] =
    useState<Record<string, UnassignedRoadmapInterface>>(forceSelected)

  useEffect(() => {
    setSelected(forceSelected)
    setOptions(Object.values(forceSelected))
  }, [forceSelected])

  const debouncedSearch = useMemo(() => {
    return debounce(async (searchString: string) => {
      const request = getAllUnassignedRoadmaps(searchString).then(resp =>
        resp.data.results.sort(epicsFirst),
      )

      try {
        const roadmaps = await request
        setOptions(roadmaps.filter(isNotSelected))
      } finally {
        setSearchPending(false)
      }
    }, 1000)
  }, [])

  const onSearch = (searchString?: string) => {
    if (searchString === searchValue) {
      return
    }
    setOptions([])
    setSearchValue(searchString)
    if (searchString) {
      setSearchPending(true)
      debouncedSearch(searchString)
    }
  }

  useEffect(() => {
    onSelectionChange(selected)
  }, [selected])

  const isSelected = (r: UnassignedRoadmapInterface) => !!selected[r.id]
  const isNotSelected = (r: UnassignedRoadmapInterface) => !isSelected(r)
  const epicsFirst = (a: UnassignedRoadmapInterface, b: UnassignedRoadmapInterface) => {
    if (
      a.issue_type === b.issue_type ||
      (a.issue_type !== IssueTypes.Epic && b.issue_type !== IssueTypes.Epic)
    ) {
      return 0
    }
    return a.issue_type === IssueTypes.Epic ? -1 : 1
  }

  const getSearchFieldIcon = () => {
    if (searchPending) {
      return undefined
    }

    return searchValue ? (
      <Icon
        name="Cross"
        onClick={() => onSearch(undefined)}
        style={{ cursor: 'pointer' }}
      />
    ) : (
      <Icon name="Search" />
    )
  }

  const renderOptions = () => {
    const targetEpics = Object.values(selected)
    const deleteSelected = (id: number) => {
      const { [id]: omit, ...rest } = selected
      setSelected(rest)
    }

    return (
      <VStack space="s-12">
        <Widget p="s-12">
          {targetEpics.length > 0 && (
            <>
              <Caption color={Token.color.greyTone50}>Epics</Caption>
              <Spacer height="s-12" />
            </>
          )}
          <List variant="compact">
            {Object.values(selected).map(roadmap => (
              <List.Item
                useIcon={
                  <Icon
                    style={{ cursor: 'pointer' }}
                    name="MinusCircle"
                    color={Token.color.red}
                    onClick={() => deleteSelected(roadmap.id)}
                  />
                }
                key={roadmap.id}
              >
                <HStack gap="s-12">
                  <Link href={roadmap.epic_url} variant="h6" target="_blank">
                    <Text variant="primary">{roadmap.key}:</Text>
                  </Link>
                  <Text variant="primary">{roadmap.epic_name}</Text>
                </HStack>
              </List.Item>
            ))}
          </List>
        </Widget>
      </VStack>
    )
  }

  return (
    <VStack space="s-16">
      <Input
        containerRef={inputRef}
        placeholder="Search epics"
        data-testid="sidebar-multiselect-new-input"
        pending={searchPending}
        onChange={ev => onSearch((ev?.currentTarget as HTMLInputElement)?.value)}
        renderAction={getSearchFieldIcon}
        {...inputProps}
        value={searchValue}
      />
      <CheckboxSelect<
        UnassignedRoadmapInterface,
        SelectOptionType<UnassignedRoadmapInterface>
      >
        value={[]}
        open={!!searchValue && !searchPending}
        anchorRef={inputRef}
        onClose={() => setSearchValue('')}
        options={options.map(option => ({
          value: option,
          label: option.display_name,
          key: option.id,
          disabled: option.issue_type !== IssueTypes.Epic,
        }))}
        labelList="Issues"
        fitInAnchor
        labelNoResults="No issues found"
        labelApply="Add epics"
        labelClear="Cancel"
        type="confirm"
        onChange={epicOptions => {
          setSelected(
            epicOptions.reduce(
              (acc, epic) => {
                if (!acc[epic.id]) {
                  acc[epic.id] = epic
                }
                return acc
              },
              { ...selected },
            ),
          )
          setSearchValue('')
        }}
      >
        {option => (
          <HStack gap="s-12">
            {!!option.value?.issue_type && (
              <Icon name={issueIconsByType[option.value?.issue_type]} />
            )}
            <Link href={option.value?.epic_url}>{option.value?.key}:</Link>
            <Text>{option.value?.epic_name}</Text>
          </HStack>
        )}
      </CheckboxSelect>
      {Object.keys(selected).length > 0 && renderOptions()}
    </VStack>
  )
}

export const AddJiraRoadmapsWidget = ({
  onAfterSubmit,
  entityId,
  entityType,
  reviewCycle,
}: {
  onAfterSubmit: () => void
  entityType: EntityTypes
  entityId?: number
  reviewCycle: ReviewCyclesInterface
}) => {
  const [submitPending, setSubmitPending] = useState(false)
  const [selected, setSelected] = useState<Record<string, UnassignedRoadmapInterface>>({})
  const [cleanSelected, setCleanSelected] = useState<{}>({}) // need this to clean up selected items in the search widget
  const [team, setTeam] = useState<TeamInterface>()

  const onSubmit = async () => {
    if (!entityId && !team) {
      return
    }

    setSubmitPending(true)
    try {
      const keys = Object.values(selected).map(r => r.key)
      await addRoadmaps(entityType, entityId || team?.id!, keys, reviewCycle.id)
      onAfterSubmit()
      setSelected({})
      setCleanSelected({})
    } finally {
      setSubmitPending(false)
    }
  }

  const selectedCount = Object.keys(selected).length

  return (
    <VStack width="100%" space="s-16">
      <Text variant="h5">Add roadmap</Text>
      <InputGroup>
        <RadioSelectInput<TeamInterface>
          minWidth={250}
          value={team}
          label="Select a team"
          selector={selectorKeys.team}
          onChange={t => setTeam(t || undefined)}
        />
        <JiraSearchWidget
          message={`Epics will be added to the ${reviewCycle?.name}  roadmap`}
          onSelectionChange={setSelected}
          forceSelected={cleanSelected}
        />
      </InputGroup>
      {selectedCount ? (
        <Button
          variant="primary"
          disabled={!selectedCount || (!team && !entityId)}
          pending={submitPending}
          onClick={onSubmit}
        >
          {chain(`Add ${pluralize('roadmap', selectedCount)}`, selectedCount)}
        </Button>
      ) : null}
    </VStack>
  )
}
