import { useGetPerformanceSettings } from '@src/api/performanceSettings'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageWrapper } from '@src/components/Page/Page'
import React from 'react'
import { GoalForm } from './Form/GoalForm'
import { PageActions } from '@src/components/Page/PageActions'
import { Button, Flex } from '@revolut/ui-kit'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { GoalsInterface } from '@src/interfaces/goals'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { Link, useLocation } from 'react-router-dom'
import { UpdateTypes } from '@src/interfaces/kpis'
import { useGetReviewCycles } from '@src/api/reviewCycles'
import { EntityTypes } from '@src/constants/api'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { IdAndName, Statuses } from '@src/interfaces'
import { PageBody } from '@src/components/Page/PageBody'

export const GoalFormPage = () => {
  const { values, submit } = useLapeContext<GoalsInterface>()
  const { data: performanceSettings } = useGetPerformanceSettings()
  const { data: reviewCycles } = useGetReviewCycles()
  const location = useLocation<{ reviewCycleId?: string }>()

  const backRoutes: Record<'employees' | 'teams' | 'department', string> = {
    employees: ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.GOALS.PERSONAL,
    teams: ROUTES.FORMS.TEAM.GOALS,
    department: ROUTES.FORMS.DEPARTMENT.GOALS,
  }

  const backUrl = values.content_type?.model
    ? pathToUrl(backRoutes[values.content_type?.model], {
        id: values.object_id,
      })
    : ROUTES.FORMS.COMPANY.GOALS

  const onSubmit = async () => {
    const originalKpis = values.kpis

    values.kpis.forEach(kpi => {
      if (kpi) {
        if (values.id && kpi.update_type !== UpdateTypes.roadmap) {
          // @ts-expect-error
          delete kpi.target_epics
        }

        if (values.id && kpi.update_type === UpdateTypes.roadmap) {
          // @ts-expect-error
          delete kpi.targets
        }

        if (values.id && (kpi.status as unknown as IdAndName<Statuses>)?.id) {
          kpi.status = (kpi.status as unknown as IdAndName<Statuses>).id
        }
      }
    })

    if (values.status.id === Statuses.draft) {
      // buisiness requirement to transit from draft status on a first submit
      values.status = { id: Statuses.on_track, name: 'On track ' }
    }

    return submit().catch(err => {
      values.kpis = originalKpis
      throw err
    })
  }

  const contentLabelByType: Record<EntityTypes, string> = {
    [EntityTypes.department]: 'department',
    [EntityTypes.team]: 'team',
    [EntityTypes.teams]: 'team',
    [EntityTypes.employees]: 'employee',
    [EntityTypes.employee]: 'employee',
    [EntityTypes.company]: 'company',
    [EntityTypes.function]: 'function',
    [EntityTypes.role]: 'role',
    [EntityTypes.specialisation]: 'specialisation',
  }

  const isDefaultCycle = (cycle: ReviewCyclesInterface) => {
    return location.state?.reviewCycleId
      ? cycle.id === Number(location.state.reviewCycleId)
      : cycle.offset === 0
  }

  const entityTitle = values.content_type?.model
    ? contentLabelByType[values.content_type.model]
    : 'Company'

  const title =
    values.status.id !== Statuses.draft && values.content_type?.model
      ? `Edit ${entityTitle} goal`
      : `Add new${values.content_type ? ` ${entityTitle}` : ''} goal`

  return performanceSettings ? (
    <PageWrapper>
      <PageHeader
        variant="narrow"
        noWrap={false}
        title={title}
        backUrl={backUrl}
        subtitle="Define the goal details"
      />
      <PageBody maxWidth="100%">
        <GoalForm
          enforceParentKpi={performanceSettings.enable_mandatory_parent_kpi}
          defaultReviewCycle={reviewCycles?.results.find(isDefaultCycle)}
        />

        <PageActions alignSelf="center" aria-label="page actions" mt="s-24">
          <Flex justifyContent="center" maxWidth="340px" alignSelf="center" gap="s-8">
            <Button variant="secondary" use={Link} to={backUrl}>
              Back
            </Button>
            <NewSaveButtonWithPopup<GoalsInterface>
              hideWhenNoChanges={false}
              successText={values.id ? undefined : 'Goal created'}
              useValidator
              disabled={!values.kpis?.length && values.update_type?.id === 'target_based'}
              onClick={onSubmit}
              elevated
              previewUrl={goal => pathToUrl(ROUTES.FORMS.GOAL.PREVIEW, { id: goal.id })}
            >
              {values.status.id === Statuses.draft ? 'Submit' : 'Save changes'}
            </NewSaveButtonWithPopup>
          </Flex>
        </PageActions>
      </PageBody>
    </PageWrapper>
  ) : null
}
