import {
  Side,
  InputGroup,
  Button,
  TextButton,
  Cell,
  VStack,
  Box,
  TransitionCollapse,
  TextArea,
} from '@revolut/ui-kit'
import React, { useEffect, ChangeEvent, useState } from 'react'
import { ticketsRequests } from '@src/api/tickets'
import Form from '@src/features/Form/Form'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { TicketInterface } from '@src/interfaces/tickets'
import SideBar from '@components/SideBar/SideBar'
import LapeSingleCheckbox from '@components/Inputs/LapeFields/LapeSingleCheckbox'
import { useOpenHelpCenter } from '@components/HelpCenter/hooks'
import NewCheckbox from '@components/Inputs/NewCheckbox/NewCheckbox'

interface BugReportSideProps {
  open: boolean
  onClose: () => void
  onSuccess?: () => void
}

interface BugReportFormProps {
  onClose: () => void
  onSuccess?: () => void
}

const getExtraInfo = () => {
  return `UserAgent: ${window.navigator.userAgent}`
}

const getUrl = () => {
  let url = new URL(window.location.href)
  url.searchParams.delete('help')
  return url.href
}

const BugReportForm = ({ onClose, onSuccess }: BugReportFormProps) => {
  const { values } = useLapeContext<TicketInterface>()
  const { openHelpCenter } = useOpenHelpCenter()
  const [shareUrl, setShareUrl] = useState(true)
  const [description, setDescription] = useState('')
  const [steps, setSteps] = useState('')

  useEffect(() => {
    if (!values.url) {
      values.url = getUrl()
    }
  }, [window.location.href])

  useEffect(() => {
    values.text = `${description}${description && steps ? '\n\n' : ''}${steps}`
  }, [description, steps])

  useEffect(() => {
    values.extra_info = getExtraInfo()
    values.is_extra_info_shared = true
    values.is_communication_allowed = true
  }, [])

  const handleExtraInfoChange = (e: ChangeEvent<HTMLInputElement>) => {
    values.is_extra_info_shared = e.currentTarget.checked
    values.extra_info = e.currentTarget.checked ? getExtraInfo() : undefined
  }

  const handleUrlShareChange = (e: ChangeEvent<HTMLInputElement>) => {
    setShareUrl(e.currentTarget.checked)
    values.url = e.currentTarget.checked ? getUrl() : undefined
  }

  return (
    <>
      <InputGroup>
        <LapeNewInput required name="title" label="Tell us what’s wrong" />
        <TextArea
          label="Issue description"
          placeholder="Example: I need to create a requisitions, but when I go in the table and try to create it I get an error"
          onChange={e => setDescription(e.currentTarget.value)}
          rows={3}
        />
        <TextArea
          label="Steps to reproduce"
          placeholder={`Example:\n• Go to the requisitions page\n• Click create requisition\n• Fill in all the fields in the form\n• Click Submit\n• Expected behaviour: Requisitions gets created\n• Actual behaviour: Requisition is not created, instead I see an internal server error`}
          onChange={e => setSteps(e.currentTarget.value)}
          rows={3}
        />
        <InputGroup>
          <Cell use="label">
            <VStack width="100%">
              <NewCheckbox
                label="Share URL where the issue happened"
                checked={shareUrl}
                onChange={handleUrlShareChange}
              />
              <TransitionCollapse in={shareUrl}>
                <Box pt="s-8">
                  <LapeNewInput name="url" label="URL" required />
                </Box>
              </TransitionCollapse>
            </VStack>
          </Cell>
          <LapeSingleCheckbox
            name="is_communication_allowed"
            label="We may contact you via email for updates"
          />
          <LapeSingleCheckbox
            name="is_extra_info_shared"
            label="Share activity logs in Revolut People"
            description="This helps us to investigate an issue"
            onChange={handleExtraInfoChange}
          />
        </InputGroup>
      </InputGroup>

      <Side.Actions horizontal>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <NewSaveButtonWithPopup
          useValidator
          onAfterSubmit={() => {
            onSuccess?.()
            onClose()
          }}
          successText="Thank you, issue was submitted"
          successDescription={
            <>
              You can find your requests in{' '}
              <TextButton
                onClick={() => {
                  openHelpCenter()
                  onClose()
                }}
              >
                Help Center
              </TextButton>
            </>
          }
          popupVariant="success-optional"
        />
      </Side.Actions>
    </>
  )
}

export const BugReportSide = ({ open, onClose, onSuccess }: BugReportSideProps) => {
  return (
    <SideBar
      useLayout
      isOpen={open}
      onClose={onClose}
      variant="wide"
      title="Report a bug"
    >
      <Form api={ticketsRequests} isExistingForm={false} disableLocalStorageCaching>
        <BugReportForm onClose={onClose} onSuccess={onSuccess} />
      </Form>
    </SideBar>
  )
}
