import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Box, Button, Group, Subheader, Item } from '@revolut/ui-kit'

import { FormPreview } from '@src/components/FormPreview/FormPreview'
import { PageBody } from '@src/components/Page/PageBody'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { DocumentsTemplatesInterface } from '@src/interfaces/documentsTemplates'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import {
  documentTemplatesFormRequest,
  downloadDocumentTemplateFile,
  downloadTemplateFileAsBlob,
} from '@src/api/documentsTemplates'
import SideBar from '@src/components/SideBar/SideBar'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import SettingsButtons from '@src/features/SettingsButtons'
import { EntityPermissions, FeatureFlags } from '@src/store/auth/types'
import { EditButton } from '@src/features/SettingsButtons/EditButton/EditButton'
import HTMLEditor from '@src/components/HTMLEditor/HTMLEditor'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { navigateTo } from '@src/actions/RouterActions'
import { AttachmentSidebarContents } from '../DocumentUpload/common'
import { DeleteButtonLape } from '../FormButtons'
import { fileSectionName } from '.'

const Preview = () => {
  const featureFlags = useSelector(selectFeatureFlags)
  const { initialValues, values } = useLapeContext<DocumentsTemplatesInterface>()

  const [downloadPending, setDownloadPending] = useState(false)
  const [attachmentPreviewOpen, setAttachmentPreviewOpen] = useState(false)
  const [attachment, setAttachment] = useState<string | null>(null)

  useEffect(() => {
    if (initialValues.file_name && attachmentPreviewOpen) {
      downloadTemplateFileAsBlob(initialValues.id!).then(response =>
        setAttachment(response),
      )
    }
  }, [attachmentPreviewOpen])

  const onDownloadAttachment = () => {
    setDownloadPending(true)

    downloadDocumentTemplateFile(initialValues.id!).finally(() =>
      setDownloadPending(false),
    )
  }

  const canDelete = initialValues.field_options?.actions?.includes(
    EntityPermissions.Delete,
  )
  const backUrl = ROUTES.PEOPLE.DOCUMENTS.TEMPLATES

  const hasFileAttached = !!initialValues.file_name
  const isRequestType = initialValues.template_type?.id === 'request'
  const isESignatureType = initialValues.template_type?.id === 'esignature'
  const showPreviewButton = hasFileAttached && (isRequestType || isESignatureType)
  const previewInTemplateEditor =
    isESignatureType &&
    featureFlags.includes(FeatureFlags.DocumentsTemplateRevamp) &&
    values.file_media_type === 'application/pdf'

  return (
    <>
      <PageHeader title={initialValues.name} backUrl={backUrl} />

      <PageBody mt="s-8">
        <SettingsButtons mb="s-16">
          <EditButton
            route={pathToUrl(
              featureFlags.includes(FeatureFlags.DocumentsTemplateRevamp)
                ? values.template_type.id === 'esignature'
                  ? ROUTES.APPS.DOCUMENTS.E_SIGNATURE_TEMPLATES.EDIT.BASICS
                  : ROUTES.APPS.DOCUMENTS.TEMPLATES.EDIT.BASICS
                : ROUTES.FORMS.DOCUMENT_TEMPLATE.GENERAL,
              {
                id: initialValues.id,
              },
            )}
          />
          {canDelete ? (
            <DeleteButtonLape
              data={values}
              backUrl={backUrl}
              deleteApi={documentTemplatesFormRequest.delete!}
              title="Document template"
              useMoreBar
            />
          ) : null}
        </SettingsButtons>

        <FormPreview title="General" data={initialValues}>
          <Group>
            <FormPreview.Item title="Name" field="name" />
            <FormPreview.Item<DocumentsTemplatesInterface>
              title="Owner"
              field="owner.name"
              to={template =>
                pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, { id: template.owner?.id })
              }
            />
            <FormPreview.Item title="Category" field="category.name" />
            <FormPreview.Item title="Template type" field="template_type.name" />
          </Group>

          <Group mt="s-16">
            <FormPreview.Details title="Description" field="description" />
          </Group>

          <Box mt="s-16">
            <Subheader variant="nested">
              <Subheader.Title>Instructions</Subheader.Title>
            </Subheader>
            <HTMLEditor
              value={initialValues.instructions}
              onChange={() => {}}
              height="auto"
              readOnly
            />
          </Box>

          <Box mt="s-16">
            <Subheader variant="nested">
              <Subheader.Title>{fileSectionName(initialValues)}</Subheader.Title>
            </Subheader>
            <Item>
              <Item.Content>{initialValues.file_name || 'No file'}</Item.Content>
              <Item.Side>
                {initialValues.file_name &&
                initialValues.template_type?.id === 'generate' ? (
                  <Button
                    onClick={onDownloadAttachment}
                    pending={downloadPending}
                    variant="secondary"
                    size="sm"
                  >
                    Download
                  </Button>
                ) : null}

                {showPreviewButton ? (
                  <Button
                    onClick={() =>
                      previewInTemplateEditor
                        ? navigateTo(
                            pathToUrl(ROUTES.FORMS.DOCUMENT_TEMPLATE.PREVIEW_IN_EDITOR, {
                              id: String(values.id),
                            }),
                          )
                        : setAttachmentPreviewOpen(!attachmentPreviewOpen)
                    }
                    variant="secondary"
                    size="sm"
                  >
                    Preview
                  </Button>
                ) : null}
              </Item.Side>
            </Item>
          </Box>
        </FormPreview>
      </PageBody>

      <SideBar
        title="Example"
        isOpen={attachmentPreviewOpen}
        onClose={() => setAttachmentPreviewOpen(false)}
        sideProps={{ resizable: true }}
      >
        <AttachmentSidebarContents
          file={attachment}
          file_media_type={initialValues.file_media_type}
          file_name={initialValues.file_name}
        />
      </SideBar>
    </>
  )
}

export default Preview
