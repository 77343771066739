import React, { useEffect, useRef, useState } from 'react'
import upperFirst from 'lodash/upperFirst'
import {
  Box,
  Dropdown,
  HStack,
  Item,
  MoreBar,
  Spacer,
  StatusWidget,
  Sticky,
  Token,
  VStack,
} from '@revolut/ui-kit'

import { downloadTemplateFileAsBlob } from '@src/api/documentsTemplates'
import { PageBody } from '@components/Page/PageBody'
import { UploadTemplateFile } from '@src/apps/People/Documents/Template/Edit/common/UploadTemplateFile'
import { PdfPreview } from '@src/features/PdfPreview'
import { ESignatureTemplateInterface } from '@src/interfaces/documentsTemplates'

import { FieldsSettingsBar } from './SettingsBar'
import { DragAndDropOverlay } from './DragAndDropOverlay'
import { useEditorViewport, useFieldActionsPopup } from './hooks'
import {
  addNewField,
  fieldTypes,
  FieldType,
  mapTypeToAvatar,
  getFieldInfoFromIndexedKey,
  createIndexedFieldKey,
  hasAnyFields,
  IndexedFieldKey,
  UseValidationErrorsReturnType,
  getFieldsByType,
} from './common'

type DropdownItemProps = {
  fieldType: FieldType
  onClick: (f: FieldType) => void
}
const DropdownItem = ({ fieldType, onClick }: DropdownItemProps) => (
  <Dropdown.Item p={0}>
    <Item
      use="label"
      aria-label={`Attach ${fieldType} field`}
      onClick={() => onClick(fieldType)}
    >
      <Item.Avatar>{mapTypeToAvatar[fieldType]}</Item.Avatar>
      <Item.Content>
        <Item.Title mr="128px">{upperFirst(fieldType)} field</Item.Title>
      </Item.Content>
    </Item>
  </Dropdown.Item>
)

const PdfLoadingErrorBanner = () => (
  <StatusWidget>
    <StatusWidget.Image image="https://assets.revolut.com/assets/3d-images/3D083.png" />
    <StatusWidget.Title>Failed to load pdf template</StatusWidget.Title>
    <StatusWidget.Description>
      Please reload the page or try again later
    </StatusWidget.Description>
  </StatusWidget>
)

const PdfRenderingErrorBanner = ({
  onRetry,
  canUploadAnotherFile,
}: {
  onRetry: () => void
  canUploadAnotherFile: boolean
}) => (
  <StatusWidget>
    <StatusWidget.Image image="https://assets.revolut.com/assets/3d-images/3D083.png" />
    <StatusWidget.Title>Failed to preview pdf template</StatusWidget.Title>
    <StatusWidget.Description>
      {canUploadAnotherFile ? 'Try uploading another one' : 'Please, try again later'}
    </StatusWidget.Description>
    {canUploadAnotherFile && (
      <StatusWidget.Action useIcon="Retry" onClick={onRetry}>
        Upload another file
      </StatusWidget.Action>
    )}
  </StatusWidget>
)

type Props<T> = {
  values: T
  errors?: UseValidationErrorsReturnType
  canUploadAnotherFile?: boolean
  initPreview?: boolean
  showEditToggle?: boolean
  stickyOffset?: number
  canShowSettings?: boolean
}
export const ESignatureTemplateEditor = <T extends ESignatureTemplateInterface>({
  values,
  errors,
  canUploadAnotherFile,
  initPreview,
  showEditToggle,
  stickyOffset = 64,
  canShowSettings = true,
}: Props<T>) => {
  const [fileUrl, setFileUrl] = useState<string>()
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [pageNum, setPageNum] = useState<number>()
  const [totalPages, setTotalPages] = useState<number>()
  const [activeToolbar, setActiveToolbar] = useState<'document' | 'settings'>()

  const [isPreview, setIsPreview] = useState(!!initPreview)
  const [showFieldsSettings, setShowFieldsSettings] = useState(
    !initPreview && !!canShowSettings && hasAnyFields(values),
  )
  const [loadingError, setLoadingError] = useState<Error>()
  const [showFileUploader, setShowFileUploader] = useState(!values.file_name)
  const [selectedFieldKey, setSelectedFieldKey] = useState<IndexedFieldKey>()

  useEffect(() => {
    if (isPreview && selectedFieldKey) {
      setShowFieldsSettings(true)
    }
  }, [selectedFieldKey])

  useEffect(() => {
    if (!values.file_name) {
      return
    }
    setFileUrl(undefined)

    downloadTemplateFileAsBlob(values.id)
      .then(res => setFileUrl(res))
      .catch(e => setLoadingError(e))
  }, [values.file_name])

  const { pageNum: forcePageNumByActiveField } = selectedFieldKey
    ? getFieldInfoFromIndexedKey(selectedFieldKey)
    : { pageNum: undefined }

  const dropdownAnchorRef = useRef<HTMLButtonElement>(null)
  const actionsPopup = useFieldActionsPopup(values, selectedFieldKey)
  const editorViewport = useEditorViewport()

  const docColMinWidth = 400
  const fieldsColWidth = 350

  if (loadingError) {
    return (
      <PageBody>
        <PdfLoadingErrorBanner />
      </PageBody>
    )
  }
  return (
    <>
      <VStack space="s-16">
        <HStack gap="s-16">
          <Box
            flex="1 1 70%"
            minWidth={docColMinWidth}
            onFocus={() => setActiveToolbar('document')}
          >
            {showFileUploader ? (
              <PageBody>
                <UploadTemplateFile onAfterUploaded={() => setShowFileUploader(false)} />
              </PageBody>
            ) : (
              <PdfPreview
                fileUrl={fileUrl}
                toolbarStickyOffset={stickyOffset}
                forcePageNum={forcePageNumByActiveField}
                toolbarActions={
                  <Box m="s-16">
                    <MoreBar maxCount={showEditToggle && !isPreview ? 2 : 1}>
                      {!isPreview && (
                        <MoreBar.Action
                          variant="primary-on-blue"
                          useIcon="Plus"
                          ref={dropdownAnchorRef}
                          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                        >
                          Add data field
                        </MoreBar.Action>
                      )}
                      {!!values.file_name && canShowSettings && (
                        <MoreBar.Action
                          useIcon="Gear"
                          onClick={() => setShowFieldsSettings(!showFieldsSettings)}
                        >
                          {showFieldsSettings ? 'Hide' : 'Show'} fields settings
                        </MoreBar.Action>
                      )}
                      {showEditToggle && (
                        <MoreBar.Action
                          useIcon={isPreview ? 'Pencil' : 'EyeShow'}
                          ref={dropdownAnchorRef}
                          onClick={() => {
                            setShowFieldsSettings(isPreview)
                            setIsPreview(!isPreview)
                          }}
                        >
                          {isPreview ? 'Edit mode' : 'Preview mode'}
                        </MoreBar.Action>
                      )}
                      {!!values.file_name && !!canUploadAnotherFile && (
                        <MoreBar.Action
                          useIcon="Retry"
                          onClick={() => setShowFileUploader(true)}
                        >
                          Upload another file
                        </MoreBar.Action>
                      )}
                    </MoreBar>
                    {pageNum && (
                      <Dropdown
                        open={isDropdownOpen}
                        anchorRef={dropdownAnchorRef}
                        onClose={() => setIsDropdownOpen(false)}
                      >
                        <VStack backgroundColor={Token.color.widgetBackground}>
                          {fieldTypes.map(fieldType => {
                            const fieldsOfSameTypeOnPage = getFieldsByType(
                              fieldType,
                              values,
                            )
                            return (
                              <DropdownItem
                                key={fieldType}
                                fieldType={fieldType}
                                onClick={() => {
                                  setSelectedFieldKey(
                                    createIndexedFieldKey(
                                      fieldType,
                                      fieldsOfSameTypeOnPage.length,
                                      pageNum - 1,
                                    ),
                                  )
                                  addNewField(
                                    values,
                                    fieldType,
                                    pageNum,
                                    editorViewport.toolbarYOverlap,
                                  )
                                  setShowFieldsSettings(true)
                                  setIsDropdownOpen(false)
                                }}
                              />
                            )
                          })}
                        </VStack>
                      </Dropdown>
                    )}
                  </Box>
                }
                onPageNumChange={(pNum, pTotal) => {
                  setPageNum(pNum)
                  setTotalPages(pTotal)
                }}
                renderErrorBanner={() => (
                  <PdfRenderingErrorBanner
                    onRetry={() => setShowFileUploader(true)}
                    canUploadAnotherFile={!!canUploadAnotherFile}
                  />
                )}
                renderPageOverlay={(
                  currentScale,
                  currentPageNum,
                  viewportWidth,
                  viewportHeight,
                ) => {
                  return (
                    <DragAndDropOverlay
                      values={values}
                      pageNum={currentPageNum}
                      scale={currentScale}
                      width={viewportWidth}
                      height={viewportHeight}
                      selectedFieldKey={selectedFieldKey}
                      setSelectedFieldKey={setSelectedFieldKey}
                      errorsByFieldsKeys={errors?.data}
                      disabled={isPreview}
                      noScrollIntoView={activeToolbar === 'document'}
                      editorViewport={editorViewport}
                    />
                  )
                }}
                documentContainerRef={editorViewport.containerRefs.doc}
                toolbarContainerRef={editorViewport.containerRefs.toolbar}
              />
            )}
            <Spacer height="30vh" />
          </Box>
          {showFieldsSettings && (
            <Box flex="1 1 30%" onFocus={() => setActiveToolbar('settings')}>
              <Sticky top={stickyOffset} width={fieldsColWidth}>
                <FieldsSettingsBar
                  values={values}
                  totalPages={totalPages}
                  selectedFieldKey={selectedFieldKey}
                  setSelectedFieldKey={setSelectedFieldKey}
                  handleRename={id => {
                    actionsPopup.showRename(id)
                  }}
                  handleDelete={id => {
                    actionsPopup.showDelete(id)
                  }}
                  errorsByFieldsKeys={errors?.data}
                  clearError={errors?.resetField}
                  disabled={isPreview}
                  noScrollIntoView={activeToolbar === 'settings'}
                />
              </Sticky>
            </Box>
          )}
        </HStack>
      </VStack>
      {actionsPopup.component}
    </>
  )
}
