import {
  ActionButton,
  ErrorWidget,
  Flex,
  Icon,
  IconName,
  Token,
  Widget,
} from '@revolut/ui-kit'
import { CellWithItem } from '@src/pages/Forms/GoalForm/common/CellWithItem'
import React, { PropsWithChildren } from 'react'

type BaseWidgetProps = {
  icon: IconName
  title: string
  desciption?: string
  preferSideAction?: boolean
  emptyState: {
    description: string
    icon?: IconName
  }
  action?: { handler: () => void; label: string }
}

export const BaseWidget = (props: PropsWithChildren<BaseWidgetProps>) => {
  const {
    icon,
    title,
    desciption,
    preferSideAction = false,
    children,
    emptyState,
    action,
  } = props

  return (
    <CellWithItem
      icon={icon}
      title={title}
      description={desciption}
      side={
        preferSideAction && action ? (
          <ActionButton onClick={action?.handler} useIcon="16/Plus">
            {action.label}
          </ActionButton>
        ) : undefined
      }
    >
      {children || (
        <Widget>
          <Flex
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            p="s-24"
          >
            <Icon name={emptyState.icon} color={Token.color.greyTone20} />
            <ErrorWidget.Description color={Token.color.greyTone20}>
              {emptyState.description}
            </ErrorWidget.Description>
            {!preferSideAction && !!action && (
              <ErrorWidget.Action onClick={action.handler}>
                {action.label}
              </ErrorWidget.Action>
            )}
          </Flex>
        </Widget>
      )}
    </CellWithItem>
  )
}
