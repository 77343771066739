import React, { useState } from 'react'
import { IconButton, Dropdown, useDropdown } from '@revolut/ui-kit'
import { useSelector } from 'react-redux'

import { MainHeaderButton } from './MainHeaderButton'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags } from '@src/store/auth/types'
import { useGetHelpCenterSettings } from '@src/api/settings'
import { useOpenHelpCenter } from '@components/HelpCenter/hooks'
import { BugReportSide } from '@components/HelpCenter/components/BugReportSide'

export const HelpCenter = () => {
  const featureFlags = useSelector(selectFeatureFlags)
  const isCommercial = featureFlags?.includes(FeatureFlags.CommercialProduct)
  const canRaiseTickets = featureFlags?.includes(FeatureFlags.TicketsApplication)
  const { data } = useGetHelpCenterSettings(isCommercial)
  const dropdown = useDropdown()
  const [openBugReport, setOpenBugReport] = useState(false)

  const { openHelpCenter } = useOpenHelpCenter()

  if (isCommercial || !data?.help_section_enabled) {
    return null
  }

  const handleOpenBugReport = () => {
    dropdown.getTargetProps().onClose()
    setOpenBugReport(true)
  }
  if (!canRaiseTickets) {
    return (
      <MainHeaderButton>
        <IconButton
          useIcon="QuestionOutline"
          aria-label="Help Center"
          size={24}
          onClick={openHelpCenter}
          color="grey-tone-50"
        />
      </MainHeaderButton>
    )
  }

  return (
    <>
      <MainHeaderButton>
        <IconButton
          useIcon="QuestionOutline"
          aria-label="Help Center"
          size={24}
          color="grey-tone-50"
          {...dropdown.getAnchorProps()}
        />
      </MainHeaderButton>
      <Dropdown {...dropdown.getTargetProps()}>
        <Dropdown.Group>
          <Dropdown.Item use="button" useIcon="Question" onClick={openHelpCenter}>
            Get Help
          </Dropdown.Item>
          <Dropdown.Item use="button" useIcon="Bug" onClick={handleOpenBugReport}>
            Report a bug
          </Dropdown.Item>
        </Dropdown.Group>
      </Dropdown>
      <BugReportSide open={openBugReport} onClose={() => setOpenBugReport(false)} />
    </>
  )
}
