import { useLapeContext } from '@src/features/Form/LapeForm'
import { GoalsInterface } from '@src/interfaces/goals'
import { BaseWidget } from '@src/pages/Forms/GoalForm/Form/Widgets/BaseWidget'
import React from 'react'
import { RoadmapsList } from './RoadmapsList'

export const RoadmapsWidget = ({
  viewMode = false,
  onAdded,
}: {
  viewMode?: boolean
  onAdded?: () => void
}) => {
  const { values } = useLapeContext<GoalsInterface>()

  const preferSideAction = !!values.roadmaps?.length
  const showCta = !!onAdded && !viewMode

  return (
    <BaseWidget
      title={viewMode ? 'Roadmap' : 'Link your roadmaps'}
      desciption={
        viewMode
          ? undefined
          : '(Optional) What projects, tasks and actions are planned to deliver the goal?'
      }
      icon="Map"
      emptyState={{
        description: 'No roadmaps added',
        icon: 'Map',
      }}
      preferSideAction={preferSideAction}
      action={showCta ? { handler: onAdded, label: 'Add roadmap' } : undefined}
    >
      {values.roadmaps && values.roadmaps.length ? (
        <RoadmapsList viewMode={viewMode} />
      ) : null}
    </BaseWidget>
  )
}
