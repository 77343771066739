import React from 'react'
import { BaseWidget } from '../BaseWidget'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { GoalsInterface } from '@src/interfaces/goals'
import { KpiInterface } from '@src/interfaces/kpis'
import { TargetsList } from './TargetsList'
import { Statuses } from '@src/interfaces'

export const TargetsWidget = ({
  viewMode = false,
  onSelected,
  onAdded,
}: {
  viewMode?: boolean
  onSelected?: (kpi: KpiInterface) => void
  onAdded?: () => void
}) => {
  const { values } = useLapeContext<GoalsInterface>()
  const updateType = values.update_type?.id
  const isDraft = values.status?.id === Statuses.draft

  const getDescription = () => {
    if (isDraft && updateType === 'cascaded') {
      return 'Progress will be measured based on parent goal performance'
    }
    if (isDraft && updateType === 'aggregated') {
      return 'Progress will be measured based on children goal(s) performance'
    }

    return 'No targets added'
  }

  const preferSideAction = !!values.kpis?.length
  const showCta = !!onAdded && !viewMode

  return (
    <BaseWidget
      preferSideAction={preferSideAction}
      action={showCta ? { handler: onAdded, label: 'Add target' } : undefined}
      title={viewMode ? 'Targets' : 'Add a target'}
      desciption={
        viewMode
          ? undefined
          : 'What are the success criteria and how is progress measured?'
      }
      icon="TurboTransfer"
      emptyState={{
        description: getDescription(),
        icon: 'TurboTransfer',
      }}
    >
      {values.kpis?.length ? (
        <TargetsList onSelected={onSelected} viewMode={viewMode} />
      ) : null}
    </BaseWidget>
  )
}
