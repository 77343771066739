import { useLapeContext } from '@src/features/Form/LapeForm'
import { GenericAnalyticsDashboardInterface } from '@src/interfaces/analyticsDashboards'
import { GoalsInterface } from '@src/interfaces/goals'
import { BaseWidget } from '@src/pages/Forms/GoalForm/Form/Widgets/BaseWidget'
import { DashboardsList } from './DashboardsList'
import React from 'react'

export const DashboardsWidget = ({
  onAdd,
  onSelected,
  viewMode = false,
}: {
  onAdd?: () => void
  onSelected: (dashboard: GenericAnalyticsDashboardInterface) => void
  viewMode?: boolean
}) => {
  const { values } = useLapeContext<GoalsInterface>()

  const preferSideAction = !!values.dashboards?.length
  const showCta = !!onAdd && !viewMode

  return (
    <BaseWidget
      title="Link supporting analytics"
      desciption="(Optional) Are there performance analytics which help to track this goal?"
      icon="ViewGrid"
      emptyState={{
        description: 'No dashboards or reports added',
        icon: 'ViewGrid',
      }}
      preferSideAction={preferSideAction}
      action={showCta ? { handler: onAdd, label: 'Add dashboard' } : undefined}
    >
      {values.dashboards?.length ? (
        <DashboardsList viewMode={viewMode} onViewRequested={onSelected} />
      ) : null}
    </BaseWidget>
  )
}
