import { useOrgEntity } from '@src/features/OrgEntityProvider/OrgEntityProvider'
import { EntityTypes } from '@src/constants/api'
import { Avatar, Banner, Tag, VStack, Text, Token } from '@revolut/ui-kit'
import React, { useMemo } from 'react'
import { GoalsTableFormWidget } from './GoalsTableWidget'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { useGetGoalContentTypeFilter } from './useGoalFilters'
import { FilterByInterface } from '@src/interfaces/data'
import { useGetReviewCycleByOffset } from '@src/api/reviewCycles'
import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict'
import pluralize from 'pluralize'
import isWithinInterval from 'date-fns/isWithinInterval'

const useInitialFilters = (currentCycle?: ReviewCyclesInterface) => {
  const { entity } = useOrgEntity()
  const contentTypeFilter = useGetGoalContentTypeFilter(entity?.type)

  return useMemo(() => {
    const initialFilters: FilterByInterface[] | null =
      currentCycle && contentTypeFilter && entity
        ? [
            {
              columnName: 'cycle__id',
              filters: [{ id: currentCycle.id, name: currentCycle.name }],
              nonResettable: true,
            },
            contentTypeFilter,
          ]
        : null

    if (initialFilters && entity && entity.type !== EntityTypes.company) {
      initialFilters.push({
        columnName: 'object_id',
        filters: [{ id: entity.data.id, name: entity.data.name || '' }],
        nonResettable: true,
      })
    }

    return initialFilters
  }, [currentCycle, contentTypeFilter, entity])
}

const useCurrentTimeline = (currentCycle?: ReviewCyclesInterface) => {
  const { entity } = useOrgEntity()
  const today = new Date()

  const isBetween = (start?: string | null, end?: string | null) => {
    return start && end
      ? isWithinInterval(today, { start: new Date(start), end: new Date(end) })
      : false
  }

  const isKpiPeriodFor = (cycle?: ReviewCyclesInterface) => {
    switch (entity?.type) {
      case EntityTypes.employee:
      case EntityTypes.employees: {
        return {
          isActive: isBetween(
            cycle?.individual_kpi_period_start_day,
            cycle?.individual_kpi_period_end_day,
          ),
          timeLeft: cycle?.individual_kpi_period_end_day
            ? formatDistanceToNowStrict(new Date(cycle.individual_kpi_period_end_day))
            : null,
        }
      }

      case EntityTypes.team:
      case EntityTypes.teams:
        return {
          isActive: isBetween(
            cycle?.team_kpi_period_start_day,
            cycle?.team_kpi_period_end_day,
          ),
          timeLeft: cycle?.team_kpi_period_end_day
            ? formatDistanceToNowStrict(new Date(cycle.team_kpi_period_end_day))
            : null,
        }

      case EntityTypes.department:
        return {
          isActive: isBetween(
            cycle?.department_kpi_period_start_day,
            cycle?.department_kpi_period_end_day,
          ),
          timeLeft: cycle?.department_kpi_period_end_day
            ? formatDistanceToNowStrict(new Date(cycle.department_kpi_period_end_day))
            : null,
        }

      default:
        return {
          isActive: false,
          timeLeft: null,
        }
    }
  }

  const isCalibrationFor = (cycle?: ReviewCyclesInterface) => {
    switch (entity?.type) {
      case EntityTypes.employee:
      case EntityTypes.employees:
      case EntityTypes.team:
      case EntityTypes.teams:
      case EntityTypes.department:
        return {
          isActive: isBetween(
            cycle?.department_kpi_period_start_day,
            cycle?.head_of_function_and_department_last_calibration_day,
          ),
          timeLeft: cycle?.head_of_function_and_department_last_calibration_day
            ? formatDistanceToNowStrict(
                new Date(cycle.head_of_function_and_department_last_calibration_day),
              )
            : null,
        }

      default:
        return {
          isActive: false,
          timeLeft: null,
        }
    }
  }

  return {
    kpi: isKpiPeriodFor(currentCycle),
    calibration: isCalibrationFor(currentCycle),
  }
}

export const useBanner = (currentCycle?: ReviewCyclesInterface) => {
  const { entity } = useOrgEntity()
  const { kpi, calibration } = useCurrentTimeline(currentCycle)

  if (!entity || entity.type === EntityTypes.company) {
    return null
  }

  if (!kpi.isActive && !calibration.isActive) {
    return null
  }

  const renderBanner = (
    title: string,
    description: string,
    timeLeft: string | null,
    image: string,
    imageVersion?: string,
  ) => {
    const assetsBase = `https://assets.revolut.com/assets/${
      imageVersion ? `3d-images-${imageVersion}` : '3d-images'
    }`
    return (
      <Banner aria-label="period banner">
        <Banner.Avatar>
          <Avatar
            variant="app"
            image={{
              default: `${assetsBase}/${image}.png`,
              '2x': `${assetsBase}/${image}@2x.png`,
              '3x': `${assetsBase}/${image}@3x.png`,
            }}
            size={56}
          />
        </Banner.Avatar>
        <Banner.Content>
          <Banner.Title>{title}</Banner.Title>
          <Banner.Description>
            <VStack gap="s-12">
              <Text>{description}</Text>
              {timeLeft ? (
                <Tag
                  useIcon="Time"
                  color={Token.color.foreground}
                  variant="faded"
                  aria-label="period time left"
                >
                  <Text variant="small-tile">{timeLeft} left</Text>
                </Tag>
              ) : null}
            </VStack>
          </Banner.Description>
        </Banner.Content>
      </Banner>
    )
  }

  if (kpi.isActive) {
    return renderBanner(
      'Goal setting and approval',
      `Please add and review the ${pluralize(
        entity.type,
        1,
      )} goals for the upcoming quarter. Goals should include a clear definition of done and a target for the success criteria which tracks progress towards the outcome of the goal. Goals should be set to impact the outcome of our company goals.`,
      kpi.timeLeft,
      '3D196',
    )
  }

  if (calibration.isActive) {
    return renderBanner(
      'Time for Goal Progress Calibration!',
      `It's essential to ensure our goals are advancing as planned. Dive into this calibration process to assess the progress and adjust any discrepancies. This step ensures our efforts remain aligned with the desired outcomes and helps in identifying areas that might need more focus. Your timely attention ensures our team's continued success`,
      calibration.timeLeft,
      '3D221',
      'v2',
    )
  }

  return null
}

export const GoalsTab = () => {
  const { data: currentCycle } = useGetReviewCycleByOffset(0)
  const initialFilters = useInitialFilters(currentCycle)
  const banner = useBanner(currentCycle)

  return (
    <VStack space="s-24">
      {banner}
      {initialFilters ? <GoalsTableFormWidget initialFilters={initialFilters} /> : null}
    </VStack>
  )
}
